<template>
    <div class="building_space_card">
        <!-- 이용중 라벨(IP Speaker 사용) -->
        <span class="building_space_status" v-if="isplayyn === 'Y'">
            <img src="/media/img/using_icon.svg"  />이용중
        </span>
        <!-- 상단 설정 메뉴 버튼 -->
        <div class="building_space_settingarea">
            <div class="building_space_settingicon" @click="isOpenSpaceSetting = !isOpenSpaceSetting">
                <img alt="설정" src="/media/img/setting_icon.svg" class="show_select_box"
                />
            </div>
            <div v-if="isOpenSpaceSetting && spacepayat === 'Y'" v-click-outside="outSideClick" class="building_space_settingshow_box">
                <p class="setting_box_item" @click="movePageNoParam('UpdateBuilding')">
                    <img src="/media/img/toggle_setting.svg" class="setting_space_img" />스페이스 설정
                </p>
                <p class="setting_box_item" @click="movePageNoParam('UpdateCuration')">
                    <img src="/media/img/new_brand_setting_icon2.svg" class="setting_space_img" />큐레이션 설정
                </p>
                <p class="setting_box_item" @click="clickDisconnect()" v-if="acceptat === 'Y' && spacepayat === 'Y'">
                    <img src="/media/img/new_brand_setting_icon3.svg" class="setting_space_img" />브랜드 연결 해제
                </p>
                <p class="setting_box_item" @click="linkUpdateFranchise()" v-else>
                    <img src="/media/img/new_brand_setting_icon3.svg" class="setting_space_img" />브랜드 연결
                </p>
                <p class="setting_box_item" @click="movePageNoParam('AutoPlay')">
                    <img src="/media/img/new_brand_setting_icon4.svg" class="setting_space_img" />자동 재생 설정
                </p>
                <p class="setting_box_item" @click="movePageNoParam('ConnectSpeaker')" v-if="useipspeaker === 'Y'">
                    <img src="/media/img/new_brand_setting_icon6.svg" class="setting_space_img" />스피커 연결
                </p>

            </div>
            <div v-else-if="isOpenSpaceSetting && spacepayat !== 'Y'"
                 class="building_space_settingshow_box"
                 v-click-outside="outSideClick">
                <p class="setting_box_item" @click="deleteSpace()"><img src="/media/img/icon_delete_16px.svg"  />스페이스 삭제</p>
            </div>
        </div>
        <!-- 스페이스 별 플레이어 영역 -->
        <div class="building_playerarea" v-if="spacepayat === 'Y'">
            <!-- 로딩: isLoading-->
            <div
                v-if="isloading"
                class="building_playerdim"
                :id="`loading-space-${index}`"
            >
                <div>
                    <p class="building_loadingtitle">Loading...</p>
                </div>
            </div>
            <!-- 네트워크 에러 : networkErr-->
            <div class="building_playerdim" v-if="errorstatus === 'networkErr'">
                <div class="building_playerdim_wrap">
                    <img class="building_wifiicon" alt="설정" src="/media/img/icon_wifi.png" />
                    <p class="building_errortitle">통신 오류입니다.</p>
                    <p class="error_network_listtext">1. 스피커IP 주소를 다시 확인해주세요.</p>
                    <p class="error_network_listtext">
                        (웹사이트 주소창에 스피커IP 주소 입력 후 관리 화면이 뜨면 정상입니다. 오류 시 스피커 설치 업체에
                        문의해주세요.)
                    </p>
                    <p class="error_network_listtext">
                        2. 1번 정상 확인 시, 네트워크 오류이오니 네트워크 담당에게 문의해주세요.
                    </p>
                </div>
            </div>
            <!-- 프로그램 설치 상태 : checkProgramStatus -->
            <div class="building_playerdim cursor" v-else-if="errorstatus === 'checkProgramStatus'">
                <div class="building_playerdim_wrap">
                    <img class="building_erroricon" alt="설치 프로그램 오류" src="/media/img/icon_caution.png" />
                    <p class="building_errortext">1. 프로그램이 정상 설치/실행되었는지 확인해주세요.</p>
                    <p class="building_errortext underline cursor">
                        <a @click="downlloadBuildingFile">프로그램 다운받기</a>
                    </p>
                    <p class="building_errortext">2. 프로그램이 정상 설치되었다면 스피커 연결을 해주세요.</p>
                </div>
            </div>
            <!-- 앨범 이미지 -->
            <figure class="buildingplayer_album_img_wrap">
                <button class="buildingplayer_play_btn" v-if="isplayyn === 'Y'">
                    <img
                        :id="'btn' + 99"
                        src="/media/img/brand_stop.svg"
                        class="buildingplayer_play_btn_img"
                        @click="controlPlay"
                    />
                </button>
                <button class="buildingplayer_play_btn" v-if="isplayyn === 'N'">
                    <img
                        :id="'btn' + 99"
                        src="/media/img/brand_Play.svg"
                        class="buildingplayer_play_btn_img"
                        @click="controlPlay"
                    />
                </button>
                <img v-if="albumimg" class="buildingplayer_album_img" :src="baseimgurl+albumimg"/>
                <img v-else class="buildingplayer_album_img" src="/media/img/emptyImg/default.svg"/>
            </figure>

            <!-- 곡명 -->
            <p v-if="musicnm !== null && musicnm !== ''" class="buildingplayer_musicname" v-html="musicnm"></p>
            <p v-else class="buildingplayer_musicname">&nbsp;</p>
            <!-- 아티스트 -->
            <p v-if="artist !== null && artist !== ''" class="buildingplayer_artist" v-html="artist"></p>
            <p v-else class="buildingplayer_artist">&nbsp;</p>
            <!-- 사운드 조절 -->
            <div class="buildingplayer_volume_wrap">
                <img
                    src="/media/img/mute.svg"
                    class="buildingplayer_mute_img"
                    v-if="ismuteyn === 'Y'"
                    @click="unmutePlayingMusic()"
                />
                <img
                    src="/media/img/volumup.svg"
                    class="buildingplayer_mute_img"
                    v-else
                    @click="mutePlayingMusic()"
                />
                <div class="buildingplayer_sound_updown">
                    <input
                        type="range"
                        name="volume"
                        class="buildingplayer_sound_updown_range"
                        min="0"
                        max="100"
                        step="10"
                        v-bind:value="playvolume"
                        @input="setVolume($event)"
                    />
                </div>
            </div>

        </div>
        <div class="bspace_empty" v-else>
            <img class="buildingplayer_empty_img" src="/media/img/thumnail_building.png" />
        </div>
        <!-- 스페이스 별 스페이스 정보 영역 -->
        <div class="buildingplayer_spaceinfo">
            <div class="buildingspace_span">
                <!-- 구독 상태 -->
                <span v-if="spacepayat === 'Y'" class="space_status_text subscribe">구독중</span>
                <span v-else-if="spacepayat === 'N'" class="space_status_text undescribe">미구독</span>
                <span v-else class="space_status_text saving">저장중</span>
                <!-- 승인상태 -->
                <span class="space_status_text eorl" v-if="requestat === 'Y'">승인대기</span>
                <span class="space_status_text dhksfy" v-if="acceptat === 'Y' && spacepayat === 'Y'">브랜드 소속</span>
                <span class="space_status_text autoplay" v-if="autoplay === 'Y' && spacepayat === 'Y'">자동재생</span>
            </div>
            <h3 class="buildingplayer_spacename" v-html="spacenm" />
            <div class="buildingplayer_tagwrap">
                <!-- 분위기 -->
                <span class="buildingplayer_tag" v-if="mood !== '' && mood !== null">{{ mood }}</span>
                <!-- 고객층 -->
                <span class="buildingplayer_tag" v-if="target !== null && target.length === 1">{{ target[0] }}</span>
                <span class="buildingplayer_tag" v-else-if="target !== null && target.length > 1">{{ target[0] }} + {{ target.length - 1 }}</span>
                <!-- 선호고객층 -->
                <span class="buildingplayer_tag" v-if="targetunit !== null && targetunit.length === 1">
                    {{ targetunit[0]   }}
                </span>
                <span class="buildingplayer_tag" v-if="targetunit !== null && targetunit.length > 1">
                    {{ targetunit[0]  }} + {{  targetunit[0].length -1 }}
                </span>
                <!-- 국내곡 비율 -->
                <span class="buildingplayer_tag" v-if="domesticrate !== null && domesticrate !== ''">
                    국내{{  domesticrate }}%
                </span>
                <!-- 장르 -->
                <span class="buildingplayer_tag" v-if="genre !== null && genre.length === 1">
                    {{  genre[0] }}
                </span>
                <span class="buildingplayer_tag" v-if="genre !== null && genre.length > 1">
                    {{  genre[0] }} + {{ genre.length -1 }}
                </span>
                <!-- 시대 -->
                <span class="buildingplayer_tag" v-if="period !== null && period.length === 1">
                    {{ period[0] }}년대
                </span>
                <span class="buildingplayer_tag" v-if="period !== null && period.length > 1">
                    {{ period[0] }}년대 + {{ period.length - 1}}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import vClickOutside from 'v-click-outside';
export default {
  name: "BuildingSpaceCard",
  emits: ['move-pagenoparam', 'update-franchise', 'disconnect-brand', 'pause-music', 'play-music', 'mute-sound', 'unmute-sound', 'setting-volume', 'delete-space'],
  props: {
    index: {
      type: Number,
      default: -1,
      required: false
    },
    spaceid: {
      type: String,
      default: '',
      required: false
    },
    spacenm: {
      type: String,
      default: '',
      required: false
    },
    acceptat: {
      type: String,
      default: 'Y',
      required: false
    },
    spacepayat: {
      type: String,
      default: 'Y',
      required: false
    },
    useipspeaker: {
      type: String,
      default: 'Y',
      required: false
    },
    isplayyn: {
      type: String,
      default: 'N',
      required: false
    },
    baseimgurl: {
      type: String,
      default: '/media/album/aplayz.cover/',
      required: false
    },
    albumimg: {
      type: String,
      default: null,
      required: false
    },
    musicnm: {
      type: String,
      default: '',
      required: false
    },
    artist: {
      type: String,
      default: '',
      required: false
    },
    ismuteyn: {
      type: String,
      default: 'Y',
      required: false
    },
    playvolume: {
      type: String,
      default: '0',
      required: false
    },
    preplayvolume: {
      type: String,
      default: '0',
      required: false
    },
    requestat: {
      type: String,
      default: 'N',
      required: false
    },
    autoplay: {
      type: String,
      default: 'Y',
      required: false
    },
    mood: {
      type: String,
      default: '',
      required: false
    },
    target: {
      type: Array,
      default: () => {
        return [];
      },
      required: false
    },
    targetunit: {
      type: Array,
      default: () => {
        return [];
      },
      required: false
    },
    domesticrate: {
      type: String,
      default: '0',
      required: false
    },
    genre: {
      type: Array,
      default: () => {
        return [];
      },
      required: false
    },
    period: {
      type: Array,
      default: () => {
        return [];
      },
      required: false
    },
    errorstatus: {
      type: String,
      default: '', // isLoading, networkErr, checkProgramStatus
      required: false
    },
    brandid: {
      type: String,
      default: '',
      required: false
    },
    brandnm: {
      type: String,
      default: '',
      required: false
    },
    spacetype: {
      type: String,
      default: 'Building',
      required: false
    },
    socket: {
      type: String,
      default: 'N',
      required: false
    },
    isloading: {
      type: Boolean,
      default: false,
      required: false
    },
    spaceip: {
      type: String,
      default: null,
      required: false
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      isOpenSpaceSetting: false
    };
  },
  methods: {
    movePageNoParam (name) {
      this.$emit('move-pagenoparam', {
        spaceId: this.spaceid,
        routerName: name
      });
    },
    movePageParam (name) {
      this.$emit('move-pageparam', {
        spaceId: this.spaceid,
        routerName: name,
        params: {}
      });
    },
    outSideClick () {
      this.isOpenSpaceSetting = false;
    },
    clickDisconnect () {
      this.$emit('disconnect-brand', {
        brandId: this.brandid,
        brandNm: this.brandnm,
        spaceId: this.spaceid
      });
    },
    controlPlay () {
      if (this.isplayyn === 'Y') {
        this.$emit('pause-music', {
          spaceId: this.spaceid,
          useIpSpeaker: this.useipspeaker,
          socket: this.socket,
          spaceIndex: this.index,
          spaceIp: this.spaceip,
          spaceNm: this.spacenm
        });
      } else {
        this.$emit('play-music', {
          spaceId: this.spaceid,
          useIpSpeaker: this.useipspeaker,
          socket: this.socket,
          spaceIndex: this.index,
          spaceIp: this.spaceip,
          spaceNm: this.spacenm
        });
      }
    },
    linkUpdateFranchise () {
      this.$emit('update-franchise', {
        spaceId: this.spaceid,
        spaceNm: this.spacenm,
        spaceType: this.spacetype
      });
    },
    unmutePlayingMusic () {
      this.$emit('unmute-sound', {
        prePlayVolume: this.preplayvolume,
        playVolume: this.playvolume,
        spaceId: this.spaceid,
        spaceIp: this.spaceip,
        socket: this.socket,
        i: this.index,
        useIpSpeaker: this.useipspeaker
      });
    },
    mutePlayingMusic () {
      this.$emit('mute-sound', {
        prePlayVolume: this.preplayvolume,
        playVolume: this.playvolume,
        spaceId: this.spaceid,
        spaceIp: this.spaceip,
        socket: this.socket,
        i: this.index,
        useIpSpeaker: this.useipspeaker
      });
    },
    setVolume (e) {
      const value = e.currentTarget.value;
      this.$emit('setting-volume', {
        spaceId: this.spaceid,
        value,
        useIpSpeaker: this.useipspeaker,
        spaceIp: this.spaceip,
        socket: this.socket,
        i: this.index
      });
    },
    deleteSpace () {
      this.$emit('delete-space', {
        spaceId: this.spaceid,
        spaceNm: this.spacenm
      });
    },
    downlloadBuildingFile () {
      window.location.href = `/media/download/setup.zip?date=${new Date().getTime()}`;
    }
  }
};
</script>
<style scoped src="@/assets/css/building/buildingspacecard.css"></style>
